<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { OlyIcon } from '@olyslager/shared-components'

import poweredby from '../assets/powered-by-2021.svg'

const cdnUrl = import.meta.env.VITE_NETLIFY_CDN_URL
</script>

<template>
  <div class="footer">
    <OlyIcon
      class="footer__watermark"
      :icon-url="poweredby"
      icon-color="var(--main-icon-color-4, #fff)"
    />
    <a
      :href="`${cdnUrl}/privacy/OlyslagerPrivacyStatement.pdf`"
      target="_blank"
    >
      Olyslager privacy statement
    </a>
  </div>
</template>

<style scoped lang="scss">
.footer {
  --backgroundColor: var(--main-color-1, #4f4d4e);
  --watermarkColor: var(--main-icon-color-4, #fff);
  background-color: var(--backgroundColor);
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  &__watermark {
    width: 170px;
    margin-inline-end: 20px;
    align-self: center;
    @media print {
      fill: #000;
    }
  }
  a {
    position: absolute;
    inset-inline-end: 20px;
    bottom: 8px;
    color: var(--watermarkColor);
    font-size: 10px;
    @media print {
      display: none;
    }
  }
}
</style>
