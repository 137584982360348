<script setup lang="ts">
import { useFrontendCssVars } from '@/composables/useFrontendCssVars'

import { useSiteConfigurationStore } from './stores/siteConfiguration'

import Footer from './components/Footer.vue'
import FooterV2 from './components/FooterV2.vue'

const siteConfiguratonStore = useSiteConfigurationStore()

if (siteConfiguratonStore.rootProperties.moaVersion === 2) {
  useFrontendCssVars()
}

</script>

<template>
  <FooterV2
    v-if="siteConfiguratonStore.rootProperties.moaVersion === 2"
  />
  <Footer v-else />
</template>
