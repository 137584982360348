import { DefaultColor } from '@olyslager/customer-configuration'
import { useCreateFrontendCssVars } from '@olyslager/shared-components'

const colorMapForRoot: Map<string, DefaultColor | string> = new Map([
  ['footer-privacy-statement-text', 'primary']
])

export const useFrontendCssVars = () => {
  useCreateFrontendCssVars({
    frontendName: APP_NAME,
    colorMapForRoot
  })
}
