<script setup lang="ts">
import { computed } from 'vue'

import { OlyIcon } from '@olyslager/shared-components'

import { useSiteConfigurationStore } from '@/stores/siteConfiguration'

import poweredby from '../assets/powered-by-2024.svg'

const siteConfiguratonStore = useSiteConfigurationStore()

const cdnUrl = import.meta.env.VITE_NETLIFY_CDN_URL

const footerLogoColorClass = computed(() => {
  return {
    'footer__watermark--white': siteConfiguratonStore.frontendProperties.footerLogoColor === 'white',
    'footer__watermark--black': siteConfiguratonStore.frontendProperties.footerLogoColor === 'black'
  }
})

</script>

<template>
  <div class="footer">
    <teleport to="#footer">
      <div class="watermark-link">
        <a
          href="https://www.olyslager.com"
          target="_blank"
        >
          <OlyIcon
            class="footer__watermark"
            :class="footerLogoColorClass"
            :icon-url="poweredby"
          />
        </a>
        <a
          :href="`${cdnUrl}/privacy/OlyslagerPrivacyStatement.pdf`"
          target="_blank"
        >
          Olyslager privacy statement
        </a>
      </div>
    </teleport>
  </div>
</template>

<style scoped lang="scss">
@import '@olyslager/shared-components/scss';

.footer {
  background-color: transparent;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
}

.watermark-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  left: 5px;
  bottom: 5px;
  @media only screen and (min-width: $breakpoint-sm) {
    padding-inline-end: 20px;
    align-items: flex-end;
  }
}

.footer__watermark {
  width: 140px;
  height: auto;
  margin-bottom: 2px;
  @media only screen and (min-width: $breakpoint-sm) {
    width: 170px;
    margin-bottom: 4px;
  }
  &--white {
    fill: #ffffff;
  }
  &--black {
    fill: #000000;
  }
}

a {
  color: var(--footer-privacy-statement-text, var(--color-primary));
  font-size: 10px;
  z-index: 1;
}

</style>
