import { App, createApp, h } from 'vue'
import { createPinia } from 'pinia'
import singleSpaVue from 'single-spa-vue'

import myApp from './App.vue'

// eslint-disable-next-line no-console
console.debug(`${APP_NAME}: v${APP_VERSION}`)

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    name: 'Footer',
    render () {
      return h(myApp)
    }
  },
  handleInstance (app: App) {
    app.use(createPinia())
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
